import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-react-i18next"
import { useTranslation } from "react-i18next"
import React from "react"
import { getNiceDate } from "../../utils/date"

const NewsThumbnail = ({ news }) => {
  const { t } = useTranslation("page_news")
  const tComponent = t("component")
  return (
    <article className="media" key={news.id}>
      <Link to={"/news/" + news.slug.current}>
        <figure className="media-left">
          <div className="image is-128x128">
            <GatsbyImage
              image={news.mainImage.asset.thumbnail}
              alt={news.title + ' ' + news.description}
            />
          </div>
        </figure>
      </Link>
      <div className="media-content">
        <h2 className="title has-text-dark is-4">{news.title}</h2>
        <p className="subtitle is-4 mb-3">
          <small>{getNiceDate(news._updatedAt, "fr")}</small>
        </p>
        <p className="mb-3">{news.description}</p>
        <Link to={"/news/" + news.slug.current}>{tComponent.cta_read_more}</Link>
      </div>
    </article>
  )
}
export default NewsThumbnail
