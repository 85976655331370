import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ImagesModule from "../components/images"
import Seo from "../components/seo"
import APropos from "../components/sections/a-propos"
import TextWithPictos from "../components/sections/text-with-pictos"
import References from "../components/sections/references"
import CTA from "../components/elements/cta-button"
import useModal from "../components/use-modal"
import Testimonials from "../components/sections/testimonials"
import SectionCTA from "../components/sections/section-cta"
import DualSplit from "../components/sections/dual-split"
import Faq, { FaqP } from "../components/elements/faq"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import NewsThumbnail from "../components/elements/news-thumbnail"

const IndexPage = ({ data }) => {
  const [toggle, ContactModal] = useModal()
  const { Picture } = ImagesModule()

  const { t } = useTranslation(["page_home", "teambuildings", "slugs"])
  const tMeta = t("meta")
  const tHero = t("hero")
  const tReferences = t("references")
  const tValueProposition = t("value_proposition")
  const tAdvantages = t("advantages")
  const tAbout = t("about")
  const tNews = t("news")
  const tTestimonials = t("testimonials")
  const tFeaturedTeambuilding = t("featured_teambuilding")
  const tLastCta = t("last_cta")
  const tFaq = t("faq")
  const tFortBayard = t("teambuildings:fort_bayard")
  const tSlugs = t("slugs:slugs")

  const lastNews = data.news.edges[0]?.node

  return (
    <Layout>
      <Seo title={tMeta.title} description={tMeta.description} />
      <ContactModal />
      <section className="hero has-background">
        <Picture
          name="hp_hero"
          className="hero-background is-hidden-mobile"
          alt={tHero.image_alt}
        />
        <div className="hero-body">
          <div className="columns">
            <div className="column is-6 px-0 py-0">
              <Picture name="hp_hero" className="is-hidden-tablet" />
            </div>
            <div className="column is-4-desktop is-6-tablet has-background-dark px-6 py-6">
              <h1 className="title is-3 is-size-4-mobile has-text-light">
                <span className="title-emphasis">{tHero.title_emphasis}</span>{" "}
                {tHero.title}
              </h1>
              <h2 className="title is-3 has-text-primary">{tHero.subtitle}</h2>
              <CTA onClick={toggle}>{tHero.cta}</CTA>
            </div>
          </div>
        </div>
      </section>
      <References title={tReferences.title} />
      <section className="section">
        <div className="container is-medium">
          <div className="columns is-centered is-multiline is-variable is-8">
            <div className="column is-6 has-text-right-tablet has-text-left-mobile mb-5">
              <h2 className="title is-3 is-uppercase mb-4">
                {tValueProposition.title}
              </h2>
              <p className="mb-2">
                <Trans
                  i18nKey={tValueProposition.p1}
                  components={{ b: <b /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey={tValueProposition.p2}
                  components={{ b: <b /> }}
                />
              </p>
            </div>
            <div className="column">
              <h2 className="title is-3 is-uppercase mb-4">
                {tNews.title}
              </h2>
                {lastNews && <NewsThumbnail news={lastNews} />}
                <Link to='/news' className="button mt-5 is-primary is-outlined has-text-weight-bold">
                  {tNews.cta}
                </Link>
            </div>
          </div>
        </div>
      </section>
      <TextWithPictos
        style={{ paddingBottom: "14rem" }}
        title={tAdvantages.title}
        pictos={["partout-en-belgique", "depuis-2004", "creations-originales"]}
        pictosAlt={[
          tAdvantages.picto_1,
          tAdvantages.picto_2,
          tAdvantages.picto_3,
        ]}
      >
        <p className="mb-2">{tAdvantages.p1}</p>
        <p className="mb-2">{tAdvantages.p2}</p>
      </TextWithPictos>
      <DualSplit style={{ marginTop: "-12rem" }} />

      <APropos
        title={tAbout.title}
        imageAlt={tAbout.image_alt}
        content={
          <>
            <p className="mb-2">{tAbout.p1}</p>
            <p className="mb-5">{tAbout.p2}</p>
            <p className="has-text-centered">
              <CTA className="mb-1" to={tSlugs["/a-propos-de-semonbusiness/"]}>
                {tAbout.cta}
              </CTA>
            </p>
          </>
        }
        picture="semonbusiness-joelle-pierre"
      />
      <Testimonials title={tTestimonials.title} />
      <section className="section">
        <div className="container">
          <div className="has-text-centered mb-5">
            <h2 className="title is-3 is-uppercase">
              {tFeaturedTeambuilding.title}
            </h2>
            <h3 className="title is-4">
              {tFeaturedTeambuilding.teambuilding_title}
            </h3>
          </div>
          <div className="columns">
            <div className="column is-4 is-offset-2 has-text-right">
              <p className="mb-5">{tFeaturedTeambuilding.p1}</p>
              <p className="mb-5">{tFeaturedTeambuilding.p2}</p>
              <p className="mb-5">{tFeaturedTeambuilding.p3}</p>
              <p className="mb-5">{tFeaturedTeambuilding.p4}</p>
              <CTA onClick={toggle}>{tFeaturedTeambuilding.cta}</CTA>
            </div>
            <div className="column is-4">
              <Picture
                name="fort-bayard-5"
                className="image is-3by2"
                alt={tFortBayard.image_alt}
              />
            </div>
          </div>
          <div className="columns is-mobile">
            <div className="column is-2 is-offset-2">
              <Picture
                name="fort-bayard-1"
                alt={tFortBayard.thumbnails_alt[0]}
              />
            </div>
            <div className="column is-2">
              <Picture
                name="fort-bayard-2"
                alt={tFortBayard.thumbnails_alt[1]}
              />
            </div>
            <div className="column is-2">
              <Picture
                name="fort-bayard-12"
                alt={tFortBayard.thumbnails_alt[2]}
              />
            </div>
            <div className="column is-2">
              <Picture
                name="fort-bayard-4"
                alt={tFortBayard.thumbnails_alt[3]}
              />
            </div>
          </div>
          <div className="columns is-mobile">
            <div className="column is-2 is-offset-2">
              <Picture
                name="fort-bayard-11"
                alt={tFortBayard.thumbnails_alt[4]}
              />
            </div>
            <div className="column is-2">
              <Picture
                name="fort-bayard-6"
                alt={tFortBayard.thumbnails_alt[5]}
              />
            </div>
            <div className="column is-2">
              <Picture
                name="fort-bayard-13"
                alt={tFortBayard.thumbnails_alt[6]}
              />
            </div>
            <div className="column is-2">
              <Picture
                name="fort-bayard-8"
                alt={tFortBayard.thumbnails_alt[7]}
              />
            </div>
          </div>
        </div>
      </section>
      <SectionCTA
        title={tLastCta.title}
        picture="together"
        toggle={toggle}
        imageAlt={tLastCta.image_alt}
      >
        <p className="mb-5">{tLastCta.p1}</p>
        <p>{tLastCta.p2}</p>
      </SectionCTA>

      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6">
              <h2 className="title is-2">{tFaq.title}</h2>
              {tFaq.faqs.map(({ title, excerpt, content }, index) => (
                <Faq title={title} excerpt={excerpt} key={index}>
                  {content.map((p, index) => (
                    <FaqP key={index}>{p}</FaqP>
                  ))}
                </Faq>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    news: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { language: { eq: $language } }
      limit: 1
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          description
          _updatedAt
          _createdAt
          mainImage {
            asset {
              thumbnail: gatsbyImageData(height: 128, width: 128)
            }
          }
        }
      }
    }
  }
`
